/* eslint-disable @typescript-eslint/no-misused-promises */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router';
import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';import Platform from 'quasar/src/plugins/platform/Platform.js';import Cookies from 'quasar/src/plugins/cookies/Cookies.js';
import { useAccountStore } from 'src/stores/account';
import { useAppModeStore } from 'src/stores/appMode';
import { useCentralStore } from 'src/stores/central';
import { useConvoStore } from 'src/stores/conversations';
import { useFlexStore } from 'src/stores/flex';
import { useHostStore } from 'src/stores/host';
import { useSearchStore } from 'src/stores/search';
import { useViewStore } from 'src/stores/view';
import cloneDeep from 'lodash.clonedeep';
export default function ({ ssrContext }) {
    return __awaiter(this, void 0, void 0, function* () {
        const accountStore = useAccountStore();
        const appModeStore = useAppModeStore();
        const centralStore = useCentralStore();
        const convoStore = useConvoStore();
        const flexStore = useFlexStore();
        const hostStore = useHostStore();
        const searchStore = useSearchStore();
        const viewStore = useViewStore();
        const { default: spacesCombinedRoutes } = yield import(
        /* webpackChunkName: 'spaces routes' */
        'src/router/spacesRoutes');
        // @ts-expect-error ignore
        const routes = spacesCombinedRoutes;
        // router history mode
        const createHistory = process.env.SERVER
            ? createMemoryHistory
            : process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory;
        // init platform detection functions
        const platform = process.env.SERVER
            ? Platform.parseSSR(ssrContext || {})
            : Platform;
        // init storage functions
        const cookies = process.env.SERVER
            ? Cookies.parseSSR(ssrContext || {})
            : Cookies;
        // get user
        const userFunc = () => {
            return accountStore.user;
        };
        const user = userFunc() || cookies.get('user');
        // check if app style type is using "app"
        const prodType = process.env.prodType;
        const forceAppMode = ['false', false].indexOf(cookies.get('force-app-mode') || false) === -1 && (prodType !== 'prod');
        const isApp = platform.is.capacitor || forceAppMode;
        // check if user is admin
        let isAdmin = false;
        if (user && user.profile_role_detail) {
            isAdmin = user.profile_role_detail.filter(items => {
                return ['admin', 'super_admin'].indexOf(items.role_name) > -1;
            }).length > 0;
        }
        // check if there are admin routes in the current list
        let children = cloneDeep(routes[0].children) || [];
        let adminInRoute = -1;
        for (let i = 0; i < children.length; i++) {
            if (children[i].path === '/admin') {
                adminInRoute = Number(i);
                break;
            }
        }
        // add / remove route depending if user is admin
        if (isAdmin) {
            if (adminInRoute === -1) {
                const { default: adminRoutes } = yield import(/* webpackChunkName: 'admin routes' */ 'src/router/adminRoutes');
                children = [
                    ...children,
                    ...adminRoutes
                ];
                routes[0].children = children;
            }
        }
        else if (adminInRoute > -1) {
            children.splice(adminInRoute, 1);
            routes[0].children = children;
        }
        // if is app, replace the layout to app layout
        if (isApp) {
            routes[0].component = () => import(
            /* webpackChunkName: "App Layout" */
            'src/layouts/app/app.vue');
        }
        // search routes
        const searchRoutes = [
            'Search',
            'Location Search',
            'Location Activities Search'
        ];
        const Router = createRouter({
            scrollBehavior: (to, from) => {
                return new Promise((resolve) => {
                    if (from.name && (
                    // custom handle scroll in the methods itself
                    // to and from name are both search i.e changes
                    // in filter/ordering
                    (searchRoutes.indexOf((to.name || '').toString()) === -1) ||
                        (searchRoutes.indexOf((from.name || '').toString()) === -1))) {
                        return setTimeout(() => {
                            resolve({
                                el: '#q-app',
                                top: 0
                            });
                        }, 0);
                    }
                });
            },
            // @ts-expect-error to come back later when vue-router release their typing
            routes,
            // Leave these as is and change from quasar.conf.js instead!
            // quasar.conf.js -> build -> vueRouterMode
            // quasar.conf.js -> build -> publicPath
            history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
        });
        Router.beforeEach((to, from, next) => {
            /*
            if (to.params.locale) {
              const localeMap = {
                'sg': 'en-US',
                'zh': 'zh',
                'id': 'id'
              }
              const localeMatch = to.params.locale.toString() as keyof typeof localeMap
              if (localeMatch in localeMap) {
                centralStore.setLocale(localeMap[localeMatch])
              }
            }
            */
            var _a;
            if (
            // when to name is undefined or not found
            !to.name || (to.name && (to.name === '404'))) {
                // when this happens, its usually due to the
                // google login popup. prevent redirect if so
                if (to.path.endsWith('&prompt=') ||
                    to.path.endsWith('&prompt=consent')) {
                    next(false);
                    return;
                }
                if (to.path === '/host-dashboard/calendar') {
                    next({ name: 'Host Dashboard' });
                    viewStore.toggleShowCalendar(true);
                    return;
                }
                /******
                 * Reroutes. removable by Dec 2023
                 *****/
                // reroute be a host
                if (to.path === '/be-a-host') {
                    next({ name: 'Rent Out Spaces Flexibly' });
                    return;
                }
                // reroute host profile
                if (to.path.match(/^\/host-profile\//)) {
                    const splitPath = to.path.split('/');
                    const hostId = splitPath[splitPath.length - 1];
                    next({
                        name: 'Host Spaces For Rent',
                        params: {
                            'id': hostId
                        }
                    });
                    return;
                }
                // if name is undefined, reroute to home
                if (!to.name) {
                    next({ name: 'Search', query: to.query });
                    return;
                }
                /******
                 * Reroute end
                 *****/
            }
            if ((typeof from.name === 'undefined') && (to.name === '500')) {
                // when go to 500 directly, redirect to home
                next({ name: 'Search' });
                return;
            }
            // when chat is still sending, save route to be redirected later
            // then prevent reroutes currently
            if (convoStore.sending || convoStore.uploading) {
                convoStore.setRoute({
                    name: to.name.toString(),
                    params: to.params,
                    query: to.query
                });
                next(false);
                return;
            }
            const savedPath = centralStore.savedPath || {};
            // show inbox and notification menu on the desktop header if
            // user routes to the chat / notification page
            if (platform.is.desktop &&
                ['Inbox', 'Notifications'].indexOf(to.name) > -1) {
                const query = Object.assign({}, to.query);
                if ((to.name === 'Inbox') &&
                    (!query.chatId)) {
                    query.popup = 'inbox';
                }
                else if (to.name === 'Notifications') {
                    query.popup = 'notifications';
                }
                next({ name: 'Home', query });
                return;
            }
            // for mobile, if the popup for chat/notification is specified,
            // redirect to the inbox or notification page respectively
            if (platform.is.mobile) {
                if ((to.name === 'Search') && to.query.popup) {
                    if (to.query.popup === 'inbox') {
                        next({ name: 'Inbox' });
                        return;
                    }
                    if (to.query.popup === 'notifications') {
                        next({ name: 'Notifications' });
                        return;
                    }
                }
            }
            // app related
            if (isApp) {
                // for booking confirmation related page
                // show popup
                if (['Custom Booking', 'Booking Confirmation'].indexOf(to.name) > -1) {
                    if (to.name === 'Custom Booking') {
                        appModeStore.toggleCustomBookingDialog({
                            id: to.params.id.toString(),
                            listing: ((_a = to.query.listing) === null || _a === void 0 ? void 0 : _a.toString()) || '',
                        });
                    }
                    if (to.name === 'Booking Confirmation') {
                        appModeStore.toggleBookingDialog(true);
                    }
                    // continue with the queries as it contains vital info for querying
                    next(Object.assign(Object.assign({}, from), { query: Object.assign(Object.assign({}, from.query), to.query) }));
                    return;
                }
                // show listing type popup
                if ((['Listing', 'Property'].indexOf(to.name) > -1) &&
                    (to.params.id)) {
                    const listingId = to.params.id;
                    if (to.name === 'Listing') {
                        appModeStore.toggleSearchVenue({ id: listingId.toString() });
                    }
                    else {
                        appModeStore.toggleSearchProperty({ id: listingId.toString() });
                    }
                    if (!from.name) {
                        next({ name: 'Search', query: to.query });
                        return;
                    }
                    next(Object.assign(Object.assign({}, from), { query: Object.assign(Object.assign({}, to.query), from.query) }));
                    return;
                }
                // show credits package popup. for user to purchase credits
                // in booking popup in listing page
                if (to.name === 'Credits Package') {
                    next(false);
                    appModeStore.toggleShowCreditPurchase(true);
                    return;
                }
                // go to host popup in host
                if (([
                    'Host Profile',
                    'Host Spaces For Rent'
                ].indexOf(to.name) > -1) && to.params.id) {
                    appModeStore.setHostQuery(to.params.id.toString());
                    next(Object.assign(Object.assign({}, from), { query: Object.assign(Object.assign({}, from.query), to.query) }));
                    return;
                }
            }
            // if there are dialog opened and user press 'back',
            // prevent navigation and close the dialog instead
            if ((['Booking Confirmation'].indexOf(to.name) === -1) && ([
                'Commercial Property Form',
                'Residential Property Form'
            ].indexOf(from.name) === -1) && (viewStore.dialogOpened.length > 0) && (Object.keys(savedPath).length > 0) && (to.name === savedPath.name) && (JSON.stringify(to.query) === JSON.stringify(savedPath.query)) && (JSON.stringify(to.params) === JSON.stringify(savedPath.params))) {
                next(false);
                return;
            }
            // when booking is completed but user press 'back',
            // prevent user from being able to go back to the confirmation page
            if (from.name === 'Booking Success') {
                flexStore.clearTimeslots();
                flexStore.clearListing();
                flexStore.clearAll();
                appModeStore.toggleSearchVenue({ id: null });
                if (to.name === 'Booking Confirmation') {
                    next({ name: 'Search' });
                    return;
                }
            }
            // if host has made changes to the listings, prevent navigation
            if ((['Edit Listings'].indexOf(from.name) > -1) &&
                hostStore.changes) {
                const confirmNavigate = confirm('Changes made may not be saved. Continue?');
                if (confirmNavigate) {
                    if (['Edit Listings'].indexOf(from.name) > -1) {
                        hostStore.toggleChanges(false);
                    }
                    next(true);
                    return;
                }
                next(false);
                return;
            }
            // save previous path for custom navigation
            if (from.name &&
                (from.name !== 'Inbox') &&
                (viewStore.chatTab !== 'chat')) {
                centralStore.setSavedPath({
                    name: from.name.toString(),
                    params: from.params,
                    query: from.query
                });
            }
            // save current path for custom navigation
            centralStore.setCurrentPath({
                name: to.name.toString(),
                params: to.params,
                query: to.query
            });
            // go to next
            next(true);
        });
        Router.afterEach((to, from) => {
            var _a;
            const updatedUser = userFunc();
            const toName = to.name;
            // for app type only
            if (isApp) {
                if (toName.includes('Search')) {
                    // if search page is visited, toggle the search page visited in store
                    // for search/home page display decision in router beforeEach hook
                    const searchType = ['Search', 'Location Search', 'Location Activities Search'].indexOf(toName) > -1
                        ? 'getspaces'
                        : 'getproperty-commercial';
                    centralStore.toggleSearchPageVisited(searchType);
                }
                if (['Commercial Property Form'].indexOf(toName) > -1) {
                    centralStore.toggleSearchPageVisited('getproperty-commercial-form');
                }
                // if user navigates to inbox, close listing popup
                if (['Inbox'].indexOf(to.name) > -1) {
                    appModeStore.toggleShowAppListing(false);
                }
                // when the route hasn't been changed but the store has
                // a saved listing or property id,
                // show listing popup
                const savedPath = centralStore.savedPath || {};
                if ((Object.keys(savedPath).length > 0) &&
                    (to.name === savedPath.name) &&
                    (JSON.stringify(to.query) === JSON.stringify(savedPath.query)) &&
                    (JSON.stringify(to.params) === JSON.stringify(savedPath.params))) {
                    if (appModeStore.searchVenue) {
                        appModeStore.toggleShowAppListing(true);
                    }
                    else if (appModeStore.searchProperty) {
                        appModeStore.toggleShowAppProperty(true);
                    }
                }
                // check if user is host
                let isHost = [];
                if (updatedUser && ('profile_role_detail' in updatedUser)) {
                    isHost = updatedUser.profile_role_detail.filter(items => {
                        return items.role_name === 'host';
                    });
                }
                const appTab = appModeStore.appTab;
                /**
                 * Update selected footer tab according to route
                 * @returns {void}
                 */
                function appTabRouteFunc() {
                    // skip for listing type routes
                    if (['Property', 'Listing'].indexOf(to.name) > -1)
                        return;
                    // inbox
                    if (to.name === 'Inbox') {
                        appModeStore.toggleAppTab('chatTab');
                        return;
                    }
                    // notif
                    if (to.name === 'Notifications') {
                        appModeStore.toggleAppTab('notificationTab');
                        return;
                    }
                    // landing page
                    if (toName.includes('Search') || ([
                        'Home',
                        'Listing',
                        'Booking Success',
                        'Host Profile',
                        'Host Spaces For Rent',
                        'Commercial Property Form'
                    ].indexOf(to.name) > -1)) {
                        appModeStore.toggleAppTab('searchTab');
                        return;
                    }
                    // host related
                    if (to.path.startsWith('/host') ||
                        to.path.startsWith('/seller') || (['Host Registration', 'Host Dashboard'].indexOf(to.name) > -1)) {
                        appModeStore.toggleAppTab('hostTab');
                        // @ts-expect-error ignore
                        appModeStore.saveTabRoute('hostTab', to);
                        return;
                    }
                    // upcoming bookings quick access
                    if (to.name === 'Upcoming Orders') {
                        appModeStore.toggleAppTab('bookingTab');
                        return;
                    }
                    // favourite quick access
                    if ((to.name === 'Favourites') && ((isHost.length === 0) ||
                        (centralStore.mode !== 'getspaces'))) {
                        appModeStore.toggleAppTab('favTab');
                        return;
                    }
                    // other account related
                    if ((to.path.startsWith('/user-dashboard') || ([
                        'Contact Us', 'Policies Page', 'Rent Out Spaces Flexibly', 'List your property'
                    ].indexOf(to.name) > -1 && updatedUser))) {
                        appModeStore.toggleAppTab('accountTab');
                        // @ts-expect-error ignore
                        appModeStore.saveTabRoute('accountTab', to);
                        return;
                    }
                    if (appTab !== 'routeTab') {
                        appModeStore.toggleAppTab('routeTab');
                    }
                }
                appTabRouteFunc();
            }
            // reset create/update listing
            if (from.name === 'Create Listing') {
                if (!isApp) {
                    if (((_a = cookies.get('createStep')) === null || _a === void 0 ? void 0 : _a.toString()) === '4') {
                        cookies.set('createStep', '0');
                    }
                }
                else if (LocalStorage.getItem('createStep') === 4) {
                    LocalStorage.set('createStep', 0);
                }
            }
            if (!isApp) {
                // clear filters on navigation to places that are not search
                // or not listing related
                if ([
                    'Listing', 'Booking Confirmation', 'Booking Success', 'Property'
                ].indexOf(to.name) === -1 && (!toName.includes('Search'))) {
                    searchStore.clearFilters();
                    searchStore.resetMap();
                    searchStore.setSearchView('listing');
                }
            }
            if (to.params && to.params.id) {
                // close booking popup and clear all data if the
                // listing id changes
                // or if the page is not listing or booking confirmation
                const paramsId = to.params.id;
                if (((to.name === 'Listing') && (Number(paramsId.split('-')[paramsId.split('-').length - 1]) !== Number((flexStore.listing || {}).id))) || ((['Listing', 'Booking Confirmation', 'Booking Success'].indexOf(to.name) === -1) ||
                    (from.name === 'Booking Success'))) {
                    flexStore.toggleBookingDialog(false);
                    flexStore.clearAll();
                }
            }
            // clear venue if page is from listing but not to booking confirm
            if (!isApp && (viewStore.dialogOpened.length === 0)) {
                if ((from.name === 'Listing') &&
                    (['Booking Confirmation', 'Booking Success', 'Listing'].indexOf(to.name) === -1)) {
                    flexStore.clearListing();
                }
            }
            // show footer on landing pages
            if (to.path.indexOf('/landing') > -1 || [
                'About',
                'Rent Out Spaces Flexibly',
                'List your property',
                'Credits Package',
                'GetSpaces',
                'Klatch',
                'Property Credits Package',
                'Commercial Leads', 'Commercial Leads Details',
                'Residential Leads', 'Residential Leads Details',
            ].indexOf(to.name) > -1) {
                viewStore.toggleShowFooter(true);
            }
            else if ((!isApp ||
                ['Landing'].indexOf(to.name) > -1) || (!isApp && (to.path.indexOf('/landing') === -1 ||
                ['Home', 'Rent Out Spaces Flexibly'].indexOf(to.name) === -1))) {
                viewStore.toggleShowFooter(false);
            }
            else if (isApp) {
                viewStore.toggleShowFooter(true);
            }
            // toggle reset password popup based on query and path
            if (to.name === 'Reset Password' && to.query.token) {
                viewStore.toggleReset(true);
                if (updatedUser) {
                    void accountStore.logout();
                }
            }
            else {
                viewStore.toggleReset(false);
            }
        });
        return Router;
    });
}
